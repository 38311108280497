define("comorbidity/components/comorbidity/comorbidity-and-allergy-dashboard-row/index", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! prettier-ignore }}
  <Dashboard::PathologiesSection::Grid
    {{! @glint-expect-error }}
    class={{if (eq @patient.followUpReasonId "mental-bipolar") '!grid-cols-["auto_auto_auto_auto_auto"] ![grid-template-areas:"column1_column2_column3_column4_column5"] [&>:nth-child(1)]:[grid-area:column1] [&>:nth-child(2)]:[grid-area:column2] [&>:nth-child(3)]:[grid-area:column3] [&>:nth-child(4)]:[grid-area:column4] [&>:nth-child(5)]:[grid-area:column5]' 'mt-[10px]'}}
  >
    <Comorbidity::ComorbidityAndAllergyDashboardRow::ComorbidityTitledBox
      {{! @glint-expect-error }}
      @patient={{@patient}}
    />
    <Comorbidity::ComorbidityAndAllergyDashboardRow::AllergyTitledBox
      {{! @glint-expect-error }}
      @patient={{@patient}}
    />
    {{! @glint-expect-error }}
    {{#if (eq @patient.followUpReasonId "mental-bipolar")}}
      {{! @glint-expect-error }}
      <AdherenceRiskPrediction::TitledBox @patient={{@patient}} />
      {{! @glint-expect-error }}
      <HospitalisationRiskPrediction::TitledBox @patient={{@patient}} />
    {{/if}}
    {{! @glint-expect-error }}
    <Transmission::TitledBox @patient={{@patient}} />
  </Dashboard::PathologiesSection::Grid>
  */
  {
    "id": "H6k2v+B3",
    "block": "[[[8,[39,0],[[16,0,[52,[28,[37,2],[[30,1,[\"followUpReasonId\"]],\"mental-bipolar\"],null],\"!grid-cols-[\\\"auto_auto_auto_auto_auto\\\"] ![grid-template-areas:\\\"column1_column2_column3_column4_column5\\\"] [&>:nth-child(1)]:[grid-area:column1] [&>:nth-child(2)]:[grid-area:column2] [&>:nth-child(3)]:[grid-area:column3] [&>:nth-child(4)]:[grid-area:column4] [&>:nth-child(5)]:[grid-area:column5]\",\"mt-[10px]\"]]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,[[\"@patient\"],[[30,1]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@patient\"],[[30,1]]],null],[1,\"\\n\"],[41,[28,[37,2],[[30,1,[\"followUpReasonId\"]],\"mental-bipolar\"],null],[[[1,\"    \"],[8,[39,5],null,[[\"@patient\"],[[30,1]]],null],[1,\"\\n\"],[1,\"    \"],[8,[39,6],null,[[\"@patient\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,7],null,[[\"@patient\"],[[30,1]]],null],[1,\"\\n\"]],[]]]]]],[\"@patient\"],false,[\"dashboard/pathologies-section/grid\",\"if\",\"eq\",\"comorbidity/comorbidity-and-allergy-dashboard-row/comorbidity-titled-box\",\"comorbidity/comorbidity-and-allergy-dashboard-row/allergy-titled-box\",\"adherence-risk-prediction/titled-box\",\"hospitalisation-risk-prediction/titled-box\",\"transmission/titled-box\"]]",
    "moduleName": "comorbidity/components/comorbidity/comorbidity-and-allergy-dashboard-row/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});